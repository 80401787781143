<template>
  <div
    class="card"
    :class="[
      activated ? `card-${card.color}-active` : `card-${card.color}-hover`,
    ]"
    @click="getPrevention"
  >
    <h3 style="cursor: default;">{{ card.content }}</h3>
  </div>
</template>

<script>
export default {
  props: ['card', 'activated'],
  emits: ['isActive', 'getPrevention'],
  methods: {
    getPrevention() {
      this.$emit('isActive');
      this.$emit('getPrevention');
    },
  },
};
</script>

<style lang="scss">
.prevention-table {
  background-color: $green-90;
  border: 1px solid $green !important;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}
</style>
