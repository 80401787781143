<template>
  <div class="row">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ cause.content }}</th>
          <th>Score</th>
          <th>Market share represented by score</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="indicator in cause.indicators" :key="indicator.id">
          <tr>
            <td class="col-6">{{ indicator.content }}</td>
            <td class="col-1">{{ indicator.value }}</td>
            <td class="col-4">{{ indicator.market_score }}</td>
            <td class="col-1">
              <el-popover placement="left" :width="400" trigger="hover">
                <template #reference>
                  <i class="bi bi-info-circle"></i>
                </template>
                <template #default>
                  <div>
                    <p>{{ indicator.description }}</p>
                    <p class="mb-0">Sources:</p>
                    <ul>
                      <li
                        class="li-bullet"
                        v-for="source in indicator.data_sources"
                        :key="source.id"
                      >
                        {{ source.name }} ({{ source.reference_year }})
                      </li>
                    </ul>
                  </div>
                </template>
              </el-popover>
              <i
                class="bi bi-plus-circle ms-2 cursor-pointer"
                v-if="indicator.sub.length"
                @click="handleClick(indicator.id)"
              ></i>
            </td>
          </tr>
          <template
            v-if="
              showDetails &&
              indicator.sub.length &&
              activeIndicator === indicator.id
            "
          >
            <tr v-for="sub in indicator.sub">
              <td class="col-6 fs-16 text-italic">{{ sub.content }}</td>
              <td class="col-1 fs-16 text-italic">{{ sub.value }}</td>
              <td class="col-4 fs-16 text-italic">{{ sub.market_score }}</td>
              <td class="col-1 fs-16"></td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'FactItem',
  props: ['cause'],
  data() {
    return {
      showDetails: false,
      activeIndicator: null,
    };
  },
  methods: {
    handleClick(id) {
      this.activeIndicator = id;
      this.showDetails = !this.showDetails
    }
  },
};
</script>

<style lang="scss" scoped>
.bi-info-circle {
  color: $primary;
  cursor: pointer;
}
</style>
