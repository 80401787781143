<template>
    <div
      class="card"
      :class="`card-${card.color}-static`"
    >
      <h3 style="cursor: default;">{{ card.content }}</h3>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoType',
    props: ['card'],
  };
  </script>
  