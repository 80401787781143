<template>
  <div class="row mt-4">
    <router-link :to="{ name: 'Tools' }">
      <app-arrow mode="arrow-back" />
    </router-link>
  </div>
</template>

<script>
export default {
    name: 'BackRow'
};
</script>
