<template>
  <p class="indicator-label text-center mt-4">{{ title }}</p>
  <div class="mt-4" v-if="card.id !== 'misuse_options'">
    <BarChart
      :chartData="chartData"
      :factsheet="true"
      v-if="chartData.length"
      :highlighted="false"
    />
  </div>
  <ChartLegend :legend="legend" :warning="card.id === 'misuse_options' ? false : true"/>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import ChartLegend from '@/components/charts/ChartLegend';
import Api from '@/utils/services/Api';

export default {
  name: 'ChartType',
  props: ['card', 'activated', 'activeBranch'],
  components: {
    BarChart,
    ChartLegend,
  },
  data() {
    return {
      chartData: [],
      title: '',
      legend: [],
    };
  },
  async created() {
    this.title = this.card.content;

    const data = await Api.post(
      'charts',
      { type: 'multiple' },
      this.card.indicators
    );

    this.chartData = data.series;
    this.legend = data.legend;
  },
};
</script>
