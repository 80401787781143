<template>
  <p class="text-small text-center text-italic mt-4" v-if="warning">
    Results represent the relative material scoring on a 0-100 scale (unless
    stated otherwise) with a higher score indicating a higher risk. The results
    of country-specific indicators represent the bottleneck within the value
    chain (e.g. mining of refining). Click on the raw material scoring to see
    the scoring for other steps in the value chain. Please note that some
    materials might not appear due to current absence of data for the
    indicator(s).
  </p>
  <table class="mt-4 table chart-legend">
    <thead>
      <tr>
        <th class="col-4">Indicators</th>
        <th class="col-8">Descriptions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in legend" :key="item.name">
        <td class="col-4">
          <span class="text-bold">{{ item.name }}</span>
        </td>
        <td class="col-8">
          <div>
            <p>{{ item.description }}</p>
            <div v-if="item.data_sources.length">
              <p class="mb-0">Sources:</p>
              <ul>
                <li
                  class="li-bullet"
                  v-for="source in item.data_sources"
                  :key="source.id"
                >
                  {{ source.name }}
                  <i>(Reference year: {{ source.reference_year }})</i>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ChartLegend',
  props: {
    legend: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
