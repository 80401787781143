<template>
  <div
    class="card"
    :class="[
      activated ? `card-${card.color}-active` : `card-${card.color}-hover`,
      card.fixHeight ? 'card-fix-height' : '',
    ]"
    @click="$emit('isActive')"
  >
    <h3>{{ card.content }}</h3>
  </div>
  <div class="btn-group btn-center mt-2">
    <button
      class="card-btn"
      :class="[
        btnLeftActive && activated
          ? `${card.btnLeft.color}-btn-active`
          : `${card.btnLeft.color}-btn`,
      ]"
      @click="getNext"
    >
      {{ card.btnLeft.content }}
    </button>
    <button
      v-if="card.btnRight"
      :class="[
        btnRightActive && activated
          ? `${card.btnRight.color}-btn-active`
          : `${card.btnRight.color}-btn`,
      ]"
      @click="getPrevention"
    >
      {{ card.btnRight.content }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CardType',
  props: ['card', 'activated'],
  emits: ['getNext', 'getPrevention', 'isActive'],
  data() {
    return {
      btnLeftActive: false,
      btnRightActive: false,
    };
  },
  methods: {
    getNext() {
      if (!this.activated) {
        this.btnLeftActive = false;
      }

      this.btnLeftActive = !this.btnLeftActive;

      if (this.btnLeftActive) {
        this.btnRightActive = false;
        this.$emit('isActive');
        this.$emit('getNext');
      }
    },
    getPrevention() {
      if (!this.activated) {
        this.btnRightActive = false;
      }

      this.btnRightActive = !this.btnRightActive;

      if (this.btnRightActive) {
        this.btnLeftActive = false;
        this.$emit('isActive');
        this.$emit('getPrevention');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@include bp-between(xl, xxl) {
  .btn-group {
    .green-btn {
      padding: 0.75rem 0.75rem;
    }
  }
}
@include bp-down(xl) {
  .btn-group {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
