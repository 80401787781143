<template>
  <div class="chart-select mb-3" v-if="showSelect">
    <el-select v-model="selectedSerie" @change="handleSelect">
      <el-option
        v-for="item in seriesOptions"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
    </el-select>
  </div>
  <apexchart
    v-if="selectedSerie === 'All'"
    type="bar"
    height="1400"
    width="800"
    :options="options"
    :series="series"
    @dataPointSelection="handleClick"
  />
  <apexchart
    v-else
    type="bar"
    height="1400"
    width="800"
    :options="options"
    :series="filteredSeries"
    @dataPointSelection="handleClick"
    @legendClick="handleLegendClick"
  />
  <!-- Factsheet -->
  <el-dialog v-model="showModal">
    <div class="row mb-4">
      <div class="col-12 text-center">
        <h4 class="mb-2 text-primary">{{ factSheet.content }}</h4>
      </div>
    </div>
    <el-scrollbar max-height="500px" :always="true">
      <FactItem
        v-for="cause in factSheet.causes"
        :key="cause.id"
        :cause="cause"
      />
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false">Close</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- End factsheet -->
</template>

<script>
import FactItem from '@/components/utils/FactItem';
import Api from '@/utils/services/Api';
import _ from 'lodash';

export default {
  name: 'BarChart',
  props: ['chartData', 'factsheet', 'highlighted'],
  components: {
    FactItem,
  },
  data() {
    return {
      showModal: false,
      seriesOptions: ['All'],
      selectedSerie: 'All',
      showSelect: false,
      options: {
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#F2A649', '#777777', '#41AED9', '#5A58A6', '#777777'],
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'category',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          position: 'top',
        },
      },
      series: [
        {
          name: '',
          data: [],
        },
      ],
      filteredSeries: [
        {
          name: '',
          data: [],
        },
      ],
    };
  },
  created() {
    this.series = this.filteredSeries = this.chartData;
    if (this.series.length > 1) {
      this.seriesOptions = ['All', ...this.series.map((serie) => serie.name)];
      this.showSelect = true;
    }
  },
  methods: {
    async handleClick(event, chartContext, selectedDataPoints) {
      if (!this.factsheet) return;
      const selected =
        this.series[selectedDataPoints.seriesIndex].data[
          selectedDataPoints.dataPointIndex
        ].x;

      await this.getFactSheet(selected);
    },
    async getFactSheet(selected) {
      this.factSheet = await Api.post(
        'materials',
        { type: 'chart-factsheet' },
        {
          id: selected,
        }
      );
      this.showModal = true;
    },
    handleLegendClick(chart, seriesIndex, opts) {
      this.series = [this.chartData[seriesIndex]];
    },
    handleSelect() {
      let filteredSeries = this.series.filter(
        (serie) => serie.name === this.selectedSerie
      );

      if (this.selectedSerie === 'All') {
        this.filteredSeries = filteredSeries;
        return;
      }
      const orderedSeries = _.orderBy(filteredSeries[0].data, ['y'], ['desc']);
      this.filteredSeries = [
        {
          ...filteredSeries,
          data: orderedSeries
        }
      ]
    },
  },
};
</script>

<style lang="scss">
.apexcharts-canvas {
  margin: 0 auto;
}

.chart-select {
  .el-select {
    min-width: 40% !important;
  }
}

@include bp-down(lg) {
  .chart-select {
    .el-select {
      min-width: 100% !important;
    }
  }
}
</style>
