export default [
  {
    id: 'content',
    content: 'Mitigation measure',
  },
  {
    id: 'description',
    content: 'What is it and how does it mitigate the problem?',
  },
  {
    id: 'whom',
    content: 'Whom does it concern?',
    sub: '(e.g. business department, policymakers, other societal actors)',
  },
  {
    id: 'hurdles',
    content: 'Hurdles to consider',
    sub: '(e.g. needs for resources, collaboration with value-chain actors)',
  },
  {
    id: 'strategies',
    content: 'Enabling strategies',
  },
  {
    id: 'timeframe',
    content: 'Short-term fix or structural solution?',
  },
];

// Create table from this object then call the prevention/mitigation key from id
// 