<template>
  <div class="btn-group btn-center">
    <div v-for="(option, index) in card.options" :key="option.id">
      <button
        class="table-btn"
        :class="[
          `${color}-btn`,
          activated === option.id ? `${color}-btn-active` : `${color}-btn`,
        ]"
        @click="getTable(index, option.id)"
      >
        {{ option.content }}
      </button>
    </div>
  </div>
  <table class="table mt-4" v-if="showTable">
    <tbody>
      <tr v-for="row in tableConf" :key="row.id">
        <th>{{ row.content }}</th>
        <td v-if="row.id !== 'whom'">
          <p class="mb-0" v-if="typeof tableData[row.id] === 'string'">
            {{ tableData[row.id] }}
          </p>
          <ul v-if="tableData[row.id] instanceof Array">
            <li v-for="listItem in tableData[row.id]">
              {{ listItem }}
            </li>
          </ul>
        </td>
        <td v-else>
          <ul v-if="tableData[row.id].other?.length">
            <li v-for="listItem in tableData[row.id].other">
              {{ listItem }}
            </li>
          </ul>
          <template v-if="tableData[row.id].company?.length">
            <p class="mb-0">Within the company:</p>
            <ul>
              <li v-for="listItem in tableData[row.id].company">
                {{ listItem }}
              </li>
            </ul>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import table_conf from '@/utils/table_conf';

export default {
  name: 'TableType',
  props: ['card', 'activeBranch'],
  data() {
    return {
      tableData: {},
      tableConf: table_conf,
      showTable: false,
      activated: null,
      color: 'green',
    };
  },
  methods: {
    getTable(index, id) {
      this.activated = id;
      const table = this.card.options[index];
      this.tableData = table;
      this.showTable = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-group {
  flex-wrap: wrap;
}
.table-btn {
  margin-right: 0.5rem;
  min-height: 3rem;
  margin-top: 1rem;
}
.table {
  background-color: $green-90;
  border: 1px solid $green !important;
  margin-bottom: 0.5rem;
  margin-top: 3rem !important;
  td,
  th {
    padding: 1rem !important;
  }
  li {
    list-style-type: disc;
  }
}
</style>
